import React, { Component, createRef } from 'react';
import { connect } from "react-redux";
import { updateList } from '../actions/photoActions';
import axios from "axios";
import alertify from "alertifyjs";
import { ENDPOINT, BASE_URL } from "../constants";
import { Spinner } from 'reactstrap';
import { PayPalButton } from "react-paypal-button-v2";
import {
    Col,
    Container,
    Row,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Button,
  } from "reactstrap";
import { updateSettings } from "../actions/photoActions";

class orderSummary extends Component {
    constructor(props){
        super(props);

        var country = localStorage.getItem('country');
        
        this.state = {
            list: this.props.list,
            watermarked: [],
            countries: [],
            country: this.props.country,
            country_id: this.props.country_id,
            size: this.props.size,

            setting: null,
            delivery_type: 'mail',
            sub_total: 0,
            shipping: parseFloat(this.props.shipping_price),
            total: 0,
            coupon_loading: false,
            country_loading: false,
            order_waiting: false,
            display_paypal: false,

            v_Code: null,
            discounted_value: 0,
            discount_type: 0,
            shipping_discount_value: 0,

            loading: true,
            delivery_setting: {}
        };
        
        this.couponInput = createRef();
        this.fnameInput = createRef();
        this.lnameInput = createRef();
        this.addressInput = createRef();
        this.cityInput = createRef();
        this.stateInput = createRef();
        this.zipInput = createRef();
        this.emailInput = createRef();
        this.phoneInput = createRef();
        this.slctCountry = createRef();
    }

    componentDidMount(){
        window.scrollTo(0, 0);
        if(!this.props.shipping_price){
            this.props.history.push("/");
        }
        
        if(this.props.list.length < 1){
            this.props.history.push("/");
        }else{
            // axios.defaults.withCredentials = true;
            const config = {
                dataType: "json",
                headers: {
                    'content-type': 'application/json'
                },
            };

            axios.get(ENDPOINT+'/setting',config)
            .then(async(response) => {
                var mail_prices = response.data.setting.mail_price;
                await this.setState({
                    setting: response.data.setting,
                    delivery_type: 'mail',
                });

            })
            .catch((error) => {
                console.log(error);
                alertify.error(error.message);
            });

            // axios.defaults.withCredentials = true;
            var type_id = localStorage.getItem('type_id');
            axios.get(ENDPOINT+'/country?dtype=' + type_id, config)
            .then(async(response) => {
                if (response.data.message)
                {
                    alertify.error(response.data.message);
                    return;
                }

                this.setState({
                    loading: false,
                    countries: response.data.country
                });
            })
            .catch((error) => {
                console.log(error);
                alertify.error(error.message);
            });

            var images = this.props.list.map(img => ({ iod_image: img.base_sixty_four }));
            axios.post(ENDPOINT+'/get_watermark', { images }, config)
            .then(response => {
                console.log(response);
                if (response.data.status == 'Success')
                {
                    this.setState({ watermarked: [BASE_URL + "/" + response.data.watermark_image] })
                }
                else
                {
                    alertify.error(response.data.status);
                }
            }).catch(e => {
                console.log(e);
                alertify.error(e.message);
            })

            axios.get(ENDPOINT+"/delivery_setting")
            .then(response => {
                if (response.data.status === 'Success') {
                    let setting = response.data.setting;
                    let delivery_type = !setting.email || (!!setting.mail && setting.default === 'mail') ? "mail" : 'email';
                    this.setState({ delivery_setting: setting, delivery_type })
                } else {
                    alertify.error(response.data.status);
                }
            })
        }
    }

    recalc_values = () => {
        var setting = this.state.setting;
        var dtype = this.state.delivery_type;
        var sub_total = 0;
        var shipping = 0;
        var discounted_subtotal = 0;

        if (!setting)
        {
            return {
                sub_total,
                shipping,
                discounted_subtotal,
                total: 0
            }
        }

        if (dtype == 'email')
        {
            sub_total = setting.email_price;
            shipping = 0;
        }
        else
        {
            for (var i=0; i<this.state.list.length; i++)
            {
                var idx = this.state.list[i].quantity / 2 - 1;
                if (idx < 0 || idx >= setting.mail_price.length) continue;
                sub_total += setting.mail_price[idx];
            }
            shipping = this.state.shipping;
        }

        discounted_subtotal = sub_total * this.state.discounted_value / 100;
        sub_total -= discounted_subtotal;
        shipping -= shipping * this.state.shipping_discount_value / 100;
        return {
            sub_total,
            discounted_subtotal,
            shipping,
            total: sub_total + shipping
        }
    }

    onQuantityChanged = (value, pieces) => {
        var list = Object.assign([], this.state.list);
        var index = pieces[1];
        if (index >= 0 && index < list.length) {
            list[index].quantity = value;
        }

        this.setState({
            list
        })

        document.getElementById('quantity'+pieces[1]).innerHTML = value;
    }

    decrease_quantity = (e) =>{
        var pieces = e.target.id.split('backbutton');
        var value = parseInt(document.getElementById('quantity'+pieces[1]).innerHTML);

        if (value <= 2) return;
        this.onQuantityChanged(value - 2, pieces);
    }

    increase_quantity = (e) =>{
        var pieces = e.target.id.split('frontbutton');
        var value = parseInt(document.getElementById('quantity'+pieces[1]).innerHTML);

        if (this.state.setting.mail_price.length * 2 <= value) return;
        this.onQuantityChanged(value + 2, pieces);
    }

    handleCoupon = (e) =>{
        e.preventDefault();

        var coupon_el = document.getElementById('coupon');
        if(!/^[0-9a-zA-Z]+$/.test(this.couponInput.current.value)){
            if(this.couponInput.current.value === ""){
              alertify.error('Coupon code cannot be empty');
              coupon_el.style.border = "1px solid red";
              return;
            }else{
              alertify.error('Coupon code should only contain numbers');
              coupon_el.style.border = "1px solid red";
              return;
            }
        }else{
            coupon_el.style.border = "1px solid #ddd";
        }

        this.setState({
            coupon_loading: true,
            v_Code: this.couponInput.current.value
        });

        const config = {
            dataType: "json",
            headers: {
                'content-type': 'application/json'
            },
        };
        var data = {
            v_Code: this.couponInput.current.value
        };
        // axios.defaults.withCredentials = true;
        axios.post(ENDPOINT+'/validate_voucher', data,config)
        .then(async(response) => {
            
            this.setState({
                coupon_loading: false
            });
            if(response.data.status === "Success"){
                if(response.data.voucher[0].v_Unit === "%"){
                    this.setState({
                        discounted_value: response.data.voucher[0].v_Value,
                        discount_type: response.data.voucher[0].v_Unit,
                        shipping_discount_value: response.data.voucher[0].v_Shipping,
                    });

                    var discounted_price_per_pic = (this.state.setting.price * response.data.voucher[0].v_Value) / 100;
                    discounted_price_per_pic = this.state.setting.price - discounted_price_per_pic;

                    // for(var i = 0; i < this.props.list.length; i++){
                    //     var qty = document.getElementById('quantity'+i).innerHTML;
                    //     var value = parseInt(qty) * discounted_price_per_pic;
                    //     document.getElementById('value'+i).innerHTML = '$'+value;
                    // }
                }
                alertify.success('Coupon has been applied');
            }else{
                alertify.error('Coupon is invalid or either has been expired');
            }
           
        })
        .catch((error) => {
            console.log(error);
            alertify.error(error.message);
        });
    }

    handlePay = () =>{
        var fname_el = document.getElementById('fname');
        var lname_el = document.getElementById('lname');
        var address_el = document.getElementById('address');
        var city_el = document.getElementById('city');
        var state_el = document.getElementById('state');
        var zipcode_el = document.getElementById('zipcode');
        var email_el = document.getElementById('email');
        var phone_el = document.getElementById('phone');

        if(!/^[a-zA-Z]+$/.test(this.fnameInput.current.value)){
            if(this.fnameInput.current.value === ""){
              alertify.error('First name cannot be empty');
              fname_el.style.border = "1px solid red";
              return;
            }else{
              alertify.error('First name should only contain alphabets');
              fname_el.style.border = "1px solid red";
              return;
            }
        }else{
            fname_el.style.border = "1px solid #ddd";
        }

        if(!/^[a-zA-Z]+$/.test(this.lnameInput.current.value)){
            if(this.lnameInput.current.value === ""){
              alertify.error('Last name cannot be empty');
              lname_el.style.border = "1px solid red";
              return;
            }else{
              alertify.error('Last name should only contain alphabets');
              lname_el.style.border = "1px solid red";
              return;
            }
        }else{
            lname_el.style.border = "1px solid #ddd";
        }

        if(this.state.delivery_type === 'mail'){
            if(this.addressInput.current.value === ""){
                alertify.error('Address cannot be empty');
                address_el.style.border = "1px solid red";
                return;
            }else{
                address_el.style.border = "1px solid #ddd";
            }
        }

        // if(!/^[a-zA-Z#0-9 ]+$/.test(this.addressInput.current.value)){
        //     if(this.addressInput.current.value === ""){
        //       alertify.error('Address cannot be empty');
        //       address_el.style.border = "1px solid red";
        //       return;
        //     }else{
        //       alertify.error('Address should only contain alphabets');
        //       address_el.style.border = "1px solid red";
        //       return;
        //     }
        // }else{
        //     address_el.style.border = "1px solid #ddd";
        // }


        if(this.state.delivery_type === 'mail'){
            if(!/^[a-zA-Z ]+$/.test(this.cityInput.current.value)){
                if(this.cityInput.current.value === ""){
                alertify.error('City cannot be empty');
                city_el.style.border = "1px solid red";
                return;
                }else{
                alertify.error('City should only contain alphabets');
                city_el.style.border = "1px solid red";
                return;
                }
            }else{
                city_el.style.border = "1px solid #ddd";
            }
        }

        if(this.state.delivery_type === 'mail'){
            if(!/^[a-zA-Z ]+$/.test(this.stateInput.current.value)){
                if(this.stateInput.current.value === ""){
                alertify.error('State cannot be empty');
                state_el.style.border = "1px solid red";
                return;
                }else{
                alertify.error('State should only contain alphabets');
                state_el.style.border = "1px solid red";
                return;
                }
            }else{
                state_el.style.border = "1px solid #ddd";
            }
        }

        if(this.state.delivery_type === 'mail') {
            if(!/^[0-9]+$/.test(this.zipInput.current.value)){
                if(this.zipInput.current.value === ""){
                alertify.error('Zip code cannot be empty');
                zipcode_el.style.border = "1px solid red";
                return;
                }else{
                alertify.error('Zip code should only contain digits');
                zipcode_el.style.border = "1px solid red";
                return;
                }
            }else{
                zipcode_el.style.border = "1px solid #ddd";
            }
        }

        if(!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.emailInput.current.value)){
            if(this.emailInput.current.value === ""){
                alertify.error('Email cannot be empty');
                email_el.style.border = "1px solid red";
                return;
            }else{
            alertify.error('Invalid email');
            email_el.style.border = "1px solid red";
            return;
            }
        }else{
            email_el.style.border = "1px solid #ddd";
        }

        if(this.phoneInput.current.value !== ""){
            // if(!/^[ 0-9+]+$/.test(this.phoneInput)){
            
            //     alertify.error('Contact number should only contain numbers');
            //     phone_el.style.border = "1px solid red";
            //     return;
            // }else{
            //     phone_el.style.border = "1px solid #ddd";
            // }
        }else{
            phone_el.style.border = "1px solid #ddd";
        }

        var prices = this.recalc_values(this.state.setting, this.state.delivery_type);
        if (prices.total <= 0)
        {
            this.handleOrder()
        } 
        else {
            this.setState({
                display_paypal: true,
            });
        }
    }

    handleOrder = () =>{
        this.setState({
            order_waiting: true
        });

        var arr = [];
         
        for(var i = 0; i < this.props.list.length; i++){
            var dat = {
                iod_qty: this.state.list[i].quantity,
                iod_image: this.state.list[i].base_sixty_four,
                // iod_price: this.state.setting.price,
                // iod_total_price: this.state.setting.price * this.props.list[i].quantity
            }
            arr.push(dat);
        }

        const config = {
            dataType: "json",
            headers: {
                'content-type': 'application/json'
            },
        };

        var prices = this.recalc_values(this.state.setting, this.state.delivery_type);
        var country_id = localStorage.getItem('country_id') || ' - '; 
        country_id = country_id.split('-')[1];
        var data = {
            io_total_price: prices.sub_total,
            io_shipping_price: prices.shipping,
            country_id: country_id,
            v_Code: this.state.v_Code,
            images: arr,
            customer: {
                user_FirstName: this.fnameInput.current.value,
                user_LastName: this.lnameInput.current.value,
                user_Address: this.state.delivery_type === 'mail' ? this.addressInput.current.value : "",
                user_State: this.state.delivery_type === 'mail' ? this.stateInput.current.value : "",
                user_City: this.state.delivery_type === 'mail' ? this.cityInput.current.value : "",
                user_Zipcode: this.state.delivery_type === 'mail' ? this.zipInput.current.value : "",
                user_Email: this.emailInput.current.value,
                user_PhoneNo: this.phoneInput.current.value
            },
            io_document_type: localStorage.getItem('type_id'),
            io_mail: this.state.delivery_type == 'mail' ? 1 : 0
        };
        // axios.defaults.withCredentials = true;
        // console.log(data);
        axios.post(ENDPOINT+'/order', data,config)
        .then(async(response) => {
            console.log(response);
            this.setState({
                order_waiting: false
            });
            var arr = [];
            this.props.updateList(arr);
            this.props.history.push("/thankyou");
        })
        .catch((error) => {
            console.log(error);
            alertify.error(error.message);
        });
    }

    handleCountrySelect = () =>{
        var pieces = this.slctCountry.current.value.split("-");
        
        localStorage.setItem("country", pieces[0]);
        localStorage.setItem("country_id", this.slctCountry.current.value);
        this.setState({
            country: pieces[0],
            country_id: pieces[1],
            shipping: pieces[2],
            size: pieces[3],
        });
        
        var data = {
            country: pieces[0],
            country_id: pieces[1],
            shipping_price: pieces[2],
            size: pieces[3]
        };

        this.props.updateSettings(data);
    }

    render() {
        var num = -1;
        var imgs = this.state.watermarked.map((item, key)=>{
            num++;
            return(
                <img key={key} width="108px" className={"img"+num} src={item} />
            );
        });

        var prices = this.recalc_values(this.state.setting, this.state.delivery_type);
        num = -1;
        var rows = this.state.list.map((row, key) =>{
            num++;
            return(
                <div key={key+this.state.delivery_type} className="text" id={"text"+num}>
                    <p className="country" id={"country"+num}> {this.state.country} <br/> Style: {this.state.size} </p>
                    {
                        this.state.delivery_type == 'mail' ?
                        <div style={{ display: 'flex' }}>
                            <input type="button" className="backbutton" id={"backbutton"+num} value='-' onClick={this.decrease_quantity} />
                            <p className="quantity" id={"quantity"+num}> {row.quantity} </p>
                            <input type="button" className="frontbutton" id={"frontbutton"+num} value='+' onClick={this.increase_quantity} />
                        </div>
                        : null
                    }
                    <p className="value" id={"value"+num}> ${(prices.sub_total).toFixed(2)} </p>
                </div>
            );
        })


        var country_id = localStorage.getItem('country_id');

        return (
            <div className="root">
                {this.state.loading? <div style={{height: '100vh'}}><div className="loading"></div></div> : 
                <div className="main">
                    <div className="top_sec ord_summary expanding-container">
                        <div className="text">
                            <h1>Checkout</h1>
                        </div>
                        <div className="main_sec">
                            <div className="details_photo_container">
                                <div className="photo_container">
                                    {imgs}
                                </div>
                                <div className="details">
                                    <p className="delivery">
                                        <span style={{ fontWeight: 400 }}>Delivery:</span>
                                        {
                                            !!this.state.delivery_setting.mail &&
                                            <>
                                                <input type="radio" id="mail" name="delivery" value="mail" checked={this.state.delivery_type == 'mail'} 
                                                onClick={() => this.setState({ delivery_type: 'mail' })} />
                                                <label htmlFor="mail">Mail</label>
                                            </>
                                        }
                                        {
                                            !!this.state.delivery_setting.email &&
                                            <>
                                                <input type="radio" id="email" name="delivery" value="email" checked={this.state.delivery_type == 'email'} 
                                                onClick={() => this.setState({ delivery_type: 'email'})} />
                                                <label htmlFor="email">Email</label>
                                            </>
                                        }
                                    </p>
                                    {
                                        this.state.delivery_type == 'mail'
                                        ?
                                        <select className="cntry-slct input-controls" ref={this.slctCountry} onChange={this.handleCountrySelect} value={country_id} >
                                            {this.state.countries.map(function(country, key){
                                                return(
                                                    <option key={key} value={country.nicename+'-'+country.id+'-'+country.shipping_price+'-'+country.size}>
                                                        {country.nicename}
                                                    </option>
                                                );
                                            },this)}
                                        </select>
                                        : null
                                    }
                                    {rows}
                                    <div className="Total">
                                        <p className="subtotal_amount">Subtotal: ${(prices.sub_total).toFixed(2)}</p>
                                        {this.state.discount_type? <p className="shipping_amount" style={{color: 'green'}}>Discount: ${(prices.discounted_subtotal).toFixed(2)}</p> :""}
                                        { this.state.delivery_type == 'mail' ? <p className="shipping_amount">Shipping: ${(prices.shipping).toFixed(2)}</p> : null }
                                        
                                        <p className="total_amount">
                                            Total: ${(prices.total).toFixed(2)}
                                        </p>
                                        <p className="shipping_amount">
                                            <input type="text" id="coupon" ref={this.couponInput} className='input-controls border-box' placeholder='Coupon Code' />
                                            <input type="submit" onClick={this.handleCoupon} name="apply" value="Apply" id="apply-coupon" className='border-box' />
                                            {this.state.coupon_loading? <Spinner color="primary" /> : ''}
                                        </p>
                                    </div>
                                    
                                </div>
                            </div>
                            <div className="shipping_info_container">
                                <div className="shipping_info">
                                    <p className="header">Shipping Info</p>
                                    {/* <div className="row1">
                                        <select className="" ref={this.slctCountry} onChange={this.handleCountrySelect}>
                                            {this.state.countries.map(function(country, key){
                                                if(country.id == localStorage.getItem('country_id')){
                                                    return(
                                                        <option key={key} selected value={country.nicename+'-'+country.id}>
                                                            {country.nicename}
                                                        </option>
                                                    );
                                                }else{
                                                    return(
                                                        <option key={key} value={country.nicename+'-'+country.id}>
                                                            {country.nicename}
                                                        </option>
                                                    );
                                                }
                                            },this)}
                                        </select>
                                    </div> */}
                                    <div className="row1">
                                        <input type="text" id="fname" className="firstName" placeholder="First Name" ref={this.fnameInput}></input>
                                        <input type="text" id="lname" className="lastName" placeholder="Last Name" ref={this.lnameInput}></input>
                                    </div>
                                    {
                                        this.state.delivery_type === 'mail' &&
                                        <>
                                            <div className="row3">
                                                <input type="text" id="address" className="address" placeholder="Address" ref={this.addressInput}></input>
                                            </div>
                                            <div className="row4">
                                                <input type="text" id="city" className="city" placeholder="City" ref={this.cityInput}></input>
                                                <input type="text" id="state" className="state" placeholder="State" ref={this.stateInput}></input>
                                                <input type="text" id="zipcode" className="zipCode" placeholder="Zip Code" ref={this.zipInput}></input>
                                            </div>
                                        </>
                                    }
                                    <div className="row5">
                                        <input type="text" id="email" className="emailAddress" placeholder="Email Address" ref={this.emailInput}></input>
                                    </div>
                                    <div className="row6">
                                        <input type="text" id="phone" className="phone" placeholder="Phone (optional)" ref={this.phoneInput}></input>
                                    </div>
                                    
                                    <div className="row7">
                                    <div className={this.state.display_paypal? 'display-paypal' : 'hide-paypal'}>
                                        <PayPalButton
                                            amount={(prices.total).toFixed(2)}
                                            // shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
                                            onSuccess={(details, data) => {
                                                // this.setState({
                                                //     loading: true
                                                // });
                                                this.handleOrder();
                                            }}
                                            onError={(e) => {
                                                console.log(e);
                                            }}
                                            options={{
                                                // clientId: 'Aaxj48qVmuIciLD2zp_u0JYPFAmBKA10_AMfvITTugs4U9ltY_M473tkm-juMzeJqXAwPVXpg9Q_fwnY'
                                                clientId: 'AZFL5l-O6btrCjMuCGSSfxW38nbeWSm9Dg15RVN5jSe1xB9N1kMQd8KTMavaA8xWGPWBF-P_G_3gdi3f'
                                            }}
                                        />
                                    </div>
                                    {this.state.order_waiting? <Spinner className="primary" /> : ''}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="done">
                            {this.state.display_paypal? 
                                ''
                             : 
                                <button type="button" onClick={this.handlePay} name="button">Done</button>}
                        </div>
                        <div className="checkout_button">
                            <div className="checkout" id="paypal-button"></div>
                        </div>
                        {/* <div className="step3_checkpoint">
                            <img className="ball" src={require('../assets/images/page_not_selected_indicator.png')} />
                            <img src={require('../assets/images/page_not_selected_indicator.png')} />
                            <img src={require('../assets/images/page_selected_indicator.png')} />
                        </div> */}
                    </div>
                </div>
            }
            </div>
        )
    }
}
const mapStateToProps = (state, ownProps) =>{
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        updateList: (data) =>{dispatch(updateList(data))},
        updateSettings: (data) =>{dispatch(updateSettings(data))}
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(orderSummary);
