export const updateList = (data) =>{
    return {
        type: 'UPDATE_LIST',
        data: data,
    }
}

export const updateSettings = (data) =>{
    return {
        type: 'UPDATE_SETTINGS',
        data: data,
    }
}