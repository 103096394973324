import React, { Component } from 'react';
import alertify from 'alertifyjs';
import { connect } from "react-redux";
import { updateList } from '../actions/photoActions';

class uploadPhoto extends Component {
    constructor(props){
        super(props);

        this.state = {
            images: [],
            urls: [],
            list: [],
            size: this.props.size
        };
    }

    componentDidMount(){
        window.scrollTo(0, 0);
        if(!this.props.list.length < 1){
            var arr = [];
            for(var i = 0; i < this.props.list.length; i++){
                arr.push(this.props.list[i].base_sixty_four);
            }
            this.setState({
                urls: arr
            });
            document.getElementById('btn-done').style.display = 'block';
        }
    }

    handleSelect = () =>{
        document.getElementById('fileElem').click();
    }

    inpChange = async (e) =>{
        
      var fileInput = document.getElementById(e.target.id); 
        // var filePath = fileInput.value;
    
      // Allowing file type 
      var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;

      Array.from(fileInput.files).forEach(file => { 
        if(!allowedExtensions.exec(file.name)) {
            alertify.error('Invalid file type'); 
            fileInput.value = '';
            document.getElementById('btn-done').style.display = 'none';
            return; 
        }
      });
        
      
      document.getElementById('btn-done').style.display = 'block';
        await this.setState({
            images: fileInput.files,
        });

        Array.from(this.state.images).forEach(file => { 
            var image_base64;
            var reader = new FileReader(); 
            reader.onload = (e) => {
                image_base64 = e.target.result;
                var arr = this.state.urls;
                arr.push(image_base64);
                this.setState({
                    urls: arr
                });
            };
            reader.readAsDataURL(file);
        });
    }

    loadImageFileAsURL = () =>{

    }

    handleDone = async () =>{
        var arr = [];
        for(var i = 0; i < this.state.urls.length; i++){
            var quantity = document.getElementById('qty'+i).value;
            
            var item = {
                base_sixty_four: this.state.urls[i],
                quantity: quantity
            };
            arr.push(item);
        }
        await this.setState({
            list: arr
        });
        this.props.updateList(arr);
        this.props.history.push("/order-summary");
    }

    handleRemove = async (e) =>{
        var id = parseInt(e.target.id);
        var array = this.state.urls;
        var filtered = array.filter(function(value, index, arr){
            if(index !== id){
                return value;
            }
        });
        
        
        await this.setState({
            urls: filtered
        });

        if(this.state.urls.length == 0){
            document.getElementById('btn-done').style.display = 'none';
        }
    }

    render() {
        var num = -1;
        var imgs = this.state.urls.map(function(img, key){
            num++;
            return(
                <div key={key} style={{display: 'inline-grid', marginRight: '12px'}}>
                    <p className="rmv-btn" id={num} onClick={this.handleRemove}>x</p>
                    <img className="img0 img-border" src={img} id={'pic'+num}/>
                    <div style={{display: 'none'}}>
                        <label>Quantity</label>
                        <input className="img-border" defaultValue="2" min="2" type="number" id={'qty'+num} />
                    </div>
                </div>
            );
        }, this);
        return (
            <div className="root">
                <div className="main">
                    <div className="top_sec">
                        <div className="text">
                            <h1>Upload Photo</h1>
                        </div>
                        <div className="avatar">
                            <div className="avatar_pic">
                                {this.state.urls.length > 0 ?
                                    imgs
                                    :
                                    <img className="img0" alt="not_used" src={require('../assets/images/avatar_portrait.png')} />
                                }
                            </div>
                        </div>
                        <div className="box_container">
                            <div className="nested_box_container"></div>
                        </div>
                        <div className="photo_upload">
                            
                            <div className="">
                                <input type="file" multiple onChange={this.inpChange} autoComplete="off" id="fileElem" className="upload_file" style={{display: 'none', cursor: 'pointer'}}  accept="image/*" />

                                {this.state.urls.length > 0? <img style={{cursor: 'pointer'}} onClick={this.handleSelect} src={require('../assets/images/chooseanother.png')} /> : <img style={{cursor: 'pointer'}} onClick={this.handleSelect} src={require('../assets/images/ChooseFileDesktopButton.png')} />}
                                
                                
                                <img className="done-btn" id="btn-done" onClick={this.handleDone} src={require('../assets/images/done_button.png')} />
                            </div>

                            {/* <div className="photo_container">
                                <input type="file" autoComplete="off" id="fileElem" className="upload_file" style={{opacity: '0'}} onChange={this.loadImageFileAsURL}  accept="image/*" />
                                
                                <input type ="image" className="upload_photo_button" src={require('../assets/images/ChooseFileDesktopButton.png')} />
                                <input type ="image" className="done_photo_button" onClick={this.handleDone} src={require('../assets/images/done_button.png')} />
                            </div> */}
                        </div>
                        <div className="step2_checkpoint">
                            <img className="ball" src={require('../assets/images/page_not_selected_indicator.png')} />
                            <img src={require('../assets/images/page_selected_indicator.png')} />
                            <img src={require('../assets/images/page_not_selected_indicator.png')} />
                        </div>
                    </div>
                    <div className="middle_sec">
                        <div className="text">
                            <h1 className="header_top"></h1>
                            <div className="header_line"></div>
                            <h2 className="header_bottom"> Quick and easy to use professional service</h2>
                        </div>
                        <div className="bubbles">
                            <ul className="bubbles_top">
                                <li className="A1">
                                    <img className="img" src={require('../assets/images/white_circle.png')} />
                                    <div className="text_container">
                                        <div className="inside_header">Positioning </div>
                                        <div className="inside_text"> Full Face <br /> Forward Facing <br /> Centered </div>
                                    </div>
                                </li> 
                                <li className="A2">
                                    <img className="img" src={require('../assets/images/white_circle.png')} />
                                    <div className="text_container">
                                        <div className="inside_header">Size of Photo </div>
                                        <div className="inside_text">{this.state.size? this.state.size : '2 x 2 in'} </div>
                                    </div>
                                </li> 
                                <li className="A3">
                                    <img className="img" src={require('../assets/images/white_circle.png')} />
                                    <div className="text_container">
                                    <div className="inside_header">Headgear </div>
                                    <div className="inside_text"> None <br /> Religious attire <br /> may be allowed </div>
                                </div>
                                </li> 
                                <li className="A4">
                                    <img className="img" src={require('../assets/images/white_circle.png')} />
                                    <div className="text_container">
                                        <div className="inside_header">Eyes </div>
                                        <div className="inside_text"> Open <br /> Visible </div>
                                    </div>
                                </li> 
                            </ul>
                            <ul className="bubbles_bot">
                                <li className="B1">
                                    <img className="img" src={require('../assets/images/white_circle.png')} />
                                    <div className="text_container">
                                        <div className="inside_header">Expression </div>
                                        <div className="inside_text"> Neutral <br/> No Smile <br/> Straight Face </div>
                                    </div>
                                </li> 
                                <li className="B2">
                                    <img className="img" src={require('../assets/images/white_circle.png')} />
                                    <div className="text_container">
                                        <div className="inside_header">Quantity </div>
                                        <div className="inside_text"> Two (2) Photos </div>
                                    </div>
                                </li> 
                                <li className="B3">
                                    <img className="img" src={require('../assets/images/white_circle.png')} />
                                    <div className="text_container">
                                        <div className="inside_header">Background </div>
                                        <div className="inside_text"> White </div>
                                    </div>
                                </li> 
                                <li className="B4">
                                    <img className="img" src={require('../assets/images/white_circle.png')} />
                                    <div className="text_container">
                                        <div className="inside_header">Glasses </div>
                                        <div className="inside_text"> Avoid glare <br/> on the lenses </div>
                                    </div>
                                </li> 
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state, ownProps) =>{
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        updateList: (data) =>{dispatch(updateList(data))}
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(uploadPhoto);
