const initState = {
    list: [],
    size: '2in x 2 in',
    watermark: null,
    price: null,
    shipping_price: 8.5,
    country: 'United States',
    country_id: null,
}

const rootReducer = (state = initState, action) =>{
    if(action.type === 'UPDATE_LIST'){
        return {
            ...state,
            list: action.data,
        }
    }

    if(action.type === 'UPDATE_SETTINGS'){
        return {
            ...state,
            size: action.data.size,
            shipping_price: action.data.shipping_price,
            country: action.data.country,
            country_id: action.data.country_id,
        }
    }
    
    return state;
}
export default rootReducer;