import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import {
    Container,
    Row,
    Collapse,
    Navbar,
    NavbarToggler,
    NavbarBrand,
    Nav,
    NavItem,
    NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    NavbarText
} from 'reactstrap';

class Header extends Component {
    constructor(props){
        super(props);

        this.state = {
            isOpen: false,
        };
    }

    toggle = () => {
        this.setState({
            isOpen: !this.state.isOpen
        });
    }

    render() {
        return (
            <div>
                <Navbar color="light" light expand="md" fixed="top">
                    <div className='left_container'>
                        <NavbarBrand href="/"><img src={require('../assets/images/AAAID_Logo.png')} /></NavbarBrand>
                        <NavbarText href="/"><div className='d-sm-block'>Digital Passport Photos</div></NavbarText>
                    </div>
                    <NavbarToggler onClick={this.toggle} />
                    <Collapse isOpen={this.state.isOpen} navbar>
                        <Nav className="mr-auto" navbar>
                            {/* <NavItem>
                                <NavbarText>Digital Passport Photos</NavbarText>
                            </NavItem> */}
                            <NavItem>
                                <NavLink href='/contact'>Contact Us</NavLink>
                            </NavItem>
                        </Nav>
                        {/* <Nav>
                            <UncontrolledDropdown nav inNavbar>
                                <DropdownToggle nav>
                                    <img src={require('../assets/images/HamburgerButton.png')} />
                                </DropdownToggle>
                                
                                <DropdownMenu right>
                                    <Link to="/contact">
                                        <DropdownItem>
                                            <NavbarText>Contact</NavbarText>
                                        </DropdownItem>
                                    </Link>
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </Nav> */}
                    </Collapse>
                </Navbar>
            </div>
        )
    }
}

export default Header;
