import React from "react";
import { Col, Container, Row, Input } from "reactstrap";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

class ThankYou extends React.Component {

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    render(){
        return (
            <>
                <section className="page-single bg-grey expanding-container">
                    <div id="content-wrap">
                        <div className="section-flat">
                            <div className="section-content">
                                <Container>
                                        <div className="content-main">
                                            <div className="block-content-2 style-3">
                                                <Row>
                                                    <Col md={6}>
                                                        <h1 className="txt-center thnx-heading">Thank You!</h1>
                                                        <p className="txt-center">Your order has been received!</p>
                                                        <p>You will receive an email with information about your order shortly. Please check your spam folder if it does not appear in your inbox. Reach out to support@digitalpassportphotos.com with any questions!</p>
                                                        <Input
                                                            type="submit"
                                                            className="back-btn"
                                                            onClick={() =>{ this.props.history.push("/"); }}
                                                            value="Back to home"
                                                            style={{marginBottom: '30px'}} 
                                                        />
                                                    </Col>
                                                    <Col md={6}>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                </Container>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default ThankYou;