import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';

import * as serviceWorker from './serviceWorker';
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";
import './assets/css/main.css';
import Home from './views/Home';
import Steps from './views/Steps';
import Specifications from './views/Specifications';
import UploadPhotos from './views/uploadPhoto';
import OrderSummary from './views/orderSummary';
import ThankYou from './views/ThankYou';
import Contact from './views/Contact';
import Header from './components/Header';
import Footer from './components/Footer';
import { Provider } from 'react-redux';
import { createStore } from 'redux';
import rootReducer from "./reducers/rootReducer";

const store = createStore(rootReducer);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <BrowserRouter>
        <Header />
          <Switch>
            <Route path="/" exact render={(props) => <Home {...props} />} />
            <Route path="/steps" exact render={(props) => <Steps {...props} />} />
            <Route path="/specifications" exact render={(props) => <Specifications {...props} />} />
            <Route path="/upload-photos" exact render={(props) => <UploadPhotos {...props} />} />
            <Route path="/order-summary" exact render={(props) => <OrderSummary {...props} />} />
            <Route path="/contact" exact render={(props) => <Contact {...props} />} />
            <Route path="/thankyou" exact render={(props) => <ThankYou {...props} />} />
          </Switch>
        <Footer />
      </BrowserRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
