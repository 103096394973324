import React, { Component, createRef } from 'react';
import {
    Col,
    Container,
    Row,
    Form,
    FormGroup,
    Label,
    Input,
    FormText,
    Button,
  } from "reactstrap";
import axios from "axios";
import alertify from "alertifyjs";
import { ENDPOINT } from "../constants";

class Contact extends Component {
    constructor(props){
        super(props);

        this.nameInput = createRef();
        this.emailInput = createRef();
        this.messageInput = createRef();
    }

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    handleSubmit = (e) =>{
        e.preventDefault();

        var email_el = document.getElementById('email');
        var name_el = document.getElementById('name');
        var message_el = document.getElementById('message');

        if(!/^[a-zA-Z ]+$/.test(this.nameInput.current.value)){
        if(this.nameInput.current.value === ""){
            alertify.error('Name cannot be empty');
            name_el.style.border = "1px solid red";
            return;
        }else{
            alertify.error('Name should only contain alphabets');
            name_el.style.border = "1px solid red";
            return;
        }
        }else{
            name_el.style.border = "1px solid #ddd";
        }

        if(!/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.emailInput.current.value)){
        if(this.emailInput.current.value === ""){
            alertify.error('Email cannot be empty');
            email_el.style.border = "1px solid red";
            return;
        }else{
            alertify.error('Invalid email');
            email_el.style.border = "1px solid red";
            return;
        }
        }else{
            email_el.style.border = "1px solid #ddd";
        }

        // if(!/^[a-zA-Z0-9. ]+$/.test(this.messageInput.current.value)){
        if(this.messageInput.current.value === ""){
            alertify.error('Message cannot be empty');
            message_el.style.border = "1px solid red";
            return;
        }
        // else{
        //     alertify.error('Message should only contain alphabets, numbers, and dots');
        //     message_el.style.border = "1px solid red";
        //     return;
        // }
        // }else{
        //     message_el.style.border = "1px solid #ddd";
        // }

        const config = {
            headers: {
                'content-type': 'application/json',
            }
        };
        var data = {
            contact_Name: this.nameInput.current.value,
            contact_Email: this.emailInput.current.value,
            contact_Message: this.messageInput.current.value
        };
        axios.post(ENDPOINT+'/contact',data,config)
        .then((response) => {
            if(response.data.status === "Success"){
                alertify.success("Message has been sent");
                this.nameInput.current.value = "";
                this.emailInput.current.value = "";
                this.messageInput.current.value = "";
            }else{
                alertify.error('Somthing went wrong while sending message');
            }
        }).catch((error) => {
            console.log(error);
            alertify.error('Something went wrong while sending message');
        });

    }

    render() {
        return (
            <div className="expanding-container">
                <Container>
                    <Row>
                        <Col className="mt-5" md={12}>
                            
                        </Col>
                    </Row>
                    <Row>
                        <Col md={3}></Col>
                        <Col md={6}>
                            <div className="contact-form-heading">
                                <h2 className="mt-4">Contact Us</h2>
                            </div>
                            <Form>
                                <FormGroup>
                                    <Label for="Name">Name</Label>
                                    <Input
                                        type="text"
                                        name="Name"
                                        id="name"
                                        placeholder="Enter your Name..."
                                        innerRef={this.nameInput}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="Email">Email</Label>
                                    <Input
                                        type="email"
                                        name="Email"
                                        id="email"
                                        placeholder="Enter your Email..."
                                        innerRef={this.emailInput}
                                    />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="exampleText">Message</Label>
                                    <Input
                                        placeholder="Enter you Message..."
                                        type="textarea"
                                        name="Subject"
                                        id="message"
                                        innerRef={this.messageInput}
                                    />
                                </FormGroup>
                                <Button onClick={this.handleSubmit} className="mt-3 submit-1">Submit</Button>
                            </Form>
                        </Col>
                        <Col md={3}></Col>
                    </Row>
                    <Row>
                        <Col className="mt-5" md={12}>
                        
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}

export default Contact;
