import React, { Component } from 'react';
import { connect } from "react-redux";

class Steps extends Component {
    componentDidMount(){
        window.scrollTo(0, 0);
    }

    proceedNext = () =>{
        this.props.history.push("/specifications");
    }

    render() {
        return (
            <div className="root">
                <div className="main">
                    <div className="top_sec">
                        <div className="box_steps">
                            <div className="step1">
                                <img src={require('../assets/images/photo_step_one.png')} />
                            </div>
                            <div className="step2">
                                <img src={require('../assets/images/photo_step_2.png')} />
                            </div>
                            <div className="step3">
                                <img src={require('../assets/images/photo_step_3.png')} />
                            </div>
                        </div>
                        <button onClick={this.proceedNext} className="photo_img">Next Step</button>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state, ownProps) =>{
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        // updateSettings: (data) =>{dispatch(updateSettings(data))}
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Steps);
