import React, { Component } from 'react';

class Footer extends Component {
    render() {
        return (
		<footer className="bottom_sec">
			<div className="bottom_bar">
				<div className="bottom_left">
					<p><a href="/contact">Contact Us</a></p>
				</div>
				<div className="bottom_right">
					<p className="media_links">
						<a href="https://www.facebook.com/AAAID" target="_blank" className="facebook">
                            <img src={require('../assets/images/FacebookButton.png')} className="facebookimg" alt="Facebook" />
                        </a>
						<a href="https://www.linkedin.com/company/aaa-id-inc." target="_blank" className="linkedin">
                            <img src={require("../assets/images/LinkedinButton.png")} className="linkedinimg" alt="LinkedIn" />
                        </a>
					</p>
				</div>
			</div>
			<div className="copyright">
				<p>Copyright &copy; 2021 AAA ID Inc. All rights reserved.</p>
			</div>
		</footer>
        )
    }
}

export default Footer;