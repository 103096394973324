import React, { Component } from 'react';
import { connect } from "react-redux";

class Specifications extends Component {
    constructor(props){
        super(props);
        
        this.state = {
            size: this.props.size,
        };
    }

    componentDidMount(){
        window.scrollTo(0, 0);
    }

    proceedNext = () =>{
        this.props.history.push("/upload-photos");
    }

    render() {
        return (
            <div className="root">
                <div className="main">
                    <div className="top_sec">
                        <div className="text">
                            <h1 className="header_top">Photo Specifications for United States</h1>
                            <div className="header_line"></div>
                            <h2 className="header_bottom">Passport Photo and Visa Requirements and Rules</h2>
                        </div>
                        <div className="bubbles">
                            <ul className="bubbles_left">
                                <li className="A1">
                                    <img className="img" src={require('../assets/images/blue_circle.png')} />
                                    <div className="text_container">
                                        <div className="inside_header">Positioning </div>
                                        <div className="inside_text"> Full Face <br/> Forward Facing <br/> Centered </div>
                                    </div>
                                </li>
                                <li className="A2">
                                    <img className="img" src={require('../assets/images/blue_circle.png')} />
                                    <div className="text_container">
                                        <div className="inside_header">Expression </div>
                                        <div className="inside_text"> Neutral <br/> No Smile <br/> Straight Face </div>
                                    </div>
                                </li>
                                <li className="A3">
                                    <img className="img" src={require('../assets/images/blue_circle.png')} />
                                    <div className="text_container">
                                        <div className="inside_header">Size of Photo </div>
                                        <div className="inside_text"> {this.state.size? this.state.size : '2 in x 2 in'} </div>
                                    </div>
                                </li>
                                <li className="A4">
                                    <img className="img" src={require('../assets/images/blue_circle.png')} />
                                    <div className="text_container">
                                    <div className="inside_header">Quantity </div>
                                    <div className="inside_text"> Two (2) Photos </div>
                                    </div>
                                </li>
                            </ul>
                            <div className="phone_photo">
                                <img src={require('../assets/images/demo_girl_photo.png')} />
                            </div>
                            <ul className="bubbles_right">
                                <li className="B1">
                                    <img className="img" src={require('../assets/images/blue_circle.png')} />
                                    <div className="text_container">
                                        <div className="inside_header">Headgear </div>
                                        <div className="inside_text"> None <br/> Religious attire <br/> may be allowed </div>
                                    </div>
                                </li> 
                                <li className="B2">
                                    <img className="img" src={require('../assets/images/blue_circle.png')} />
                                    <div className="text_container">
                                        <div className="inside_header">Background </div>
                                        <div className="inside_text"> White </div>
                                    </div>
                                </li> 
                                <li className="B3">
                                    <img className="img" src={require('../assets/images/blue_circle.png')} />
                                    <div className="text_container">
                                        <div className="inside_header">Eyes </div>
                                        <div className="inside_text"> Open <br/> Visible </div>
                                    </div>
                                </li> 
                                <li className="B4">
                                    <img className="img" src={require('../assets/images/blue_circle.png')} />
                                    <div className="text_container">
                                        <div className="inside_header">Glasses </div>
                                        <div className="inside_text"> Avoid glare <br/> on the lenses </div>
                                    </div>
                                </li> 
                            </ul>
                        </div>
                        <button onClick={this.proceedNext} className="photo_img">Next Step</button>
                        <div className="step1_checkpoint">
                            <img className="ball" src={require('../assets/images/page_selected_indicator.png')} />
                            <img src={require('../assets/images/page_not_selected_indicator.png')} />
                            <img src={require('../assets/images/page_not_selected_indicator.png')} />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps = (state, ownProps) =>{
    return {
        ...state
    }
}

const mapDispatchToProps = (dispatch) =>{
    return {
        
    }
}
export default connect(mapStateToProps, mapDispatchToProps)(Specifications);
